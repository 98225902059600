import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import Container from "../components/Container"

import ContactForm from "../components/Contact-Form"
import { Grid } from "@material-ui/core"
import theme from "../themes"

const KontaktPage = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <Section paddingTop={theme.main.sizes.paddings.bigger}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={8} style={{ marginBottom: "80px" }}>
              <H1>Kontakt</H1>
              <p>Sie haben eine Frage? Wir sind für Sie da. </p>
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default KontaktPage
