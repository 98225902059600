import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Form from "./Form"
import Label from "../Label"
import TextField from "../TextField"
import Group from "./Group"
import TextArea from "../TextArea"
import H2 from "../H2"
import P from "../P"
import Button from "../Button"
import Success from "./Sucess"

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const [formSubmitted, setformSubmitted] = useState(false)

  const swissPhoneRegex = /^(0|0041|\+41)?[1-9\s][0-9\s]{1,12}$/
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = data => {
    console.log(data)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(data),
    })
      .then(() => {
        setformSubmitted(true)
      })
      .catch(error => alert(error))
  }

  if (formSubmitted)
    return (
      <Success>
        <H2>Vielen Dank!</H2>
        <P>
          Ihre Anfrage wurde erfolgreich übermittelt. Wir melden uns in kürze
          bei Ihnen!
        </P>
      </Success>
    )

  return (
    <Form
      name="kontakt"
      action="/"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <TextField
        type="hidden"
        name="form-name"
        value="kontakt"
        ref={register}
      />
      <TextField type="hidden" name="bot-field" />
      <TextField
        type="hidden"
        name="subject"
        value="Neue Anfrage"
        ref={register}
      />

      <Group>
        <Label htmlFor="fullname">Ihr Name</Label>
        <TextField
          id="fullname"
          name="fullname"
          placeholder="Max Muster"
          ref={register({
            required: true,
          })}
          error={errors.fullname ? true : undefined}
        ></TextField>
        {errors.fullname && <p>Bitte geben Sie einen Namen an</p>}
      </Group>
      <Group>
        <Label htmlFor="replyTo">Ihre E-Mail</Label>
        <TextField
          id="replyTo"
          name="replyTo"
          placeholder="max@muster.ch"
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Ungültige E-Mail!",
            },
          })}
          error={errors.replyTo ? true : undefined}
        ></TextField>
        {errors.replyTo && <p>Bitte geben Sie eine gültige E-Mail an</p>}
      </Group>
      <Group>
        <Label htmlFor="phone">Ihre Telefonnummer</Label>
        <TextField
          id="phone"
          name="phone"
          placeholder="+41 xx.. oder 07x xxx xx xx"
          ref={register({
            required: true,
            pattern: {
              value: swissPhoneRegex,
              message: "Ungültige Telefonnummer!",
            },
          })}
          error={errors.phone ? true : undefined}
        ></TextField>
        {errors.phone && <p>Bitte geben Sie eine gültige Telefonnummer an</p>}
      </Group>
      <Group>
        <Label htmlFor="message">Ihre Nachricht</Label>
        <TextArea
          id="message"
          name="message"
          placeholder="Geben Sie hier Ihr Anliegen ein. Wir melden uns in Kürze bei Ihnen."
          rows="6"
          ref={register({
            required: true,
          })}
          error={errors.message ? true : undefined}
        ></TextArea>
        {errors.message && <p>Bitte geben Sie Ihre Mitteilung ein</p>}
      </Group>

      <Group>
        <Button>Nachricht absenden</Button>
      </Group>
    </Form>
  )
}

export default ContactForm
